(function ($) {
    'use strict';

    var browserWindow = $(window);

    // :: 1.0 Preloader Active Code
    browserWindow.on('load', function () {
        $('#preloader').fadeOut('slow', function () {
            $(this).remove();
        });
    });

    // :: 2.0 Nav Active Code
    if ($.fn.classyNav) {
        $('#modeloNav').classyNav();
    }

    // :: 3.0 Sliders Active Code
    if ($.fn.owlCarousel) {
        var welcomeSlide = $('.hero-slides');
        var aboutSlide = $('.about-slides');

        welcomeSlide.owlCarousel({
            items: 1,
            autoplayHoverPause:true,
            margin: 0,
            loop: true,
            nav: true,
            navText: ['<span style="font-size: 5em;"><i class="fa fa-angle-left"></i></span>', '<span style="font-size: 5em;"><i class="fa fa-angle-right"></i></span>'],
            dots: true,
            autoplay: true,
            autoplayTimeout: 7000,
            smartSpeed: 500
        });

        welcomeSlide.on('translate.owl.carousel', function () {
            var slideLayer = $("[data-animation]");
            slideLayer.each(function () {
                var anim_name = $(this).data('animation');
                $(this).removeClass('animated ' + anim_name).css('opacity', '0');
            });
        });

        welcomeSlide.on('translated.owl.carousel', function () {
            var slideLayer = welcomeSlide.find('.owl-item.active').find("[data-animation]");
            slideLayer.each(function () {
                var anim_name = $(this).data('animation');
                $(this).addClass('animated ' + anim_name).css('opacity', '1');
            });
        });

        $("[data-delay]").each(function () {
            var anim_del = $(this).data('delay');
            $(this).css('animation-delay', anim_del);
        });

        $("[data-duration]").each(function () {
            var anim_dur = $(this).data('duration');
            $(this).css('animation-duration', anim_dur);
        });

        aboutSlide.owlCarousel({
            items: 1,
            margin: 0,
            loop: true,
            nav: true,
            navText: ['<span style="font-size: 5em;"><i class="fa fa-angle-left"></i></span>', '<span style="font-size: 5em;"><i class="fa fa-angle-right"></i></span>'],
            dots: true,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause:true,
            smartSpeed: 500
        });
    }

    // :: 4.0 Gallery Active Code
    if ($.fn.magnificPopup) {
        $('.gallery-img').magnificPopup({
            type: 'image',
            gallery: {
                enabled: true
            }
        });
    }

    // :: 5.0 ScrollUp Active Code
    if ($.fn.scrollUp) {
        browserWindow.scrollUp({
            scrollSpeed: 1000,
            scrollText: '<i class="fa fa-angle-up"></i>'
        });
    }

    
   //Count up inicia
   var fired = 0;
    $(document).ready(function () {
        if(fired === 0) {
            $('.count').each(function () {
                $(this).prop('Counter',0).animate({
                    Counter: $(this).text()
                    }, {
                    duration: 3000,
                    easing: 'swing',
                    step: function (now) {
                    $(this).text(Math.ceil(now));
                    }
                });
            });
            fired = 1; //Only do scroll function once
        }
    });

    // :: 7.0 prevent default a click
    $('a[href="#"]').click(function ($) {
        $.preventDefault()
    });
    
    // :: 9.0 Sticky Active Code
    if ($.fn.sticky) {
        $(".modelo-main-menu").sticky({
            topSpacing: 0
        });
    }
    new WOW().init();
    // :: 8.0 wow Active Code
    if (browserWindow.width() > 767) {
        
    }

    // Activa los tooltips de bootstrap
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
      })

      //Para a animacion de el scroll
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();
    
            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });
    

})(jQuery);